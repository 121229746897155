import Helmet from 'preact-helmet';
import RightPanel from '../../components/right';

const Kontakty = () => (
	<div>
		<Helmet title="Kontakty a informace" />
		<div class="first_container">
			<div class="story">
				<div class="breadcrumb">
					<h1>Kontakty a informace</h1>
					<ul>
						<li><a href="/" >Home</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li>Kontakty a informace</li>
					</ul>
				</div>
				<div id="kontakty">
					<h2>Vladislav Pichrt</h2>
					<h3>Fakturacní adresa</h3>
					<p>Lidická 1332, Ostrov, 363 01, okres Karlovy Vary</p>
					<h3>Adresa provozovny</h3>
					<p>Bor 4, Karlovy Vary-Sadov, 360 01</p>
					<ul>
						<li>Telefon: +420 776 181 078</li>
						<li>Email: <a href="mailto:pichrt&#64;hotmail.com">pichrt@hotmail.com</a></li>
					</ul>
					<ul>
						<li>DIČ: CZ7310061891</li>
						<li>IČ: 61175471</li>
					</ul>
					<ul>
						<li>Bankovní spojení: 176838122/0300</li>
						<li>IBAN: CZ08 0300 000­0 0001 7683 8­122</li>
						<li>BIC kód: CEKOCZPP</li>
					</ul>
					<p />
					<h3>Kudy k nám?</h3>
					<p>Souradnice GPS: +50° 15' 51.33", +12° 55' 51.58"</p>
				</div>
				<div id="mapa">
					<iframe class="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20404.14977704631!2d12.930887111645045!3d50.263572456748726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTDCsDE1JzUxLjMiTiAxMsKwNTUnNTEuNiJF!5e0!3m2!1scs!2scz!4v1395701309073"
						frameborder="0"
					/>
				</div>
			</div>
			<RightPanel />
			<div class="clear" />
		</div>
	</div>
);
export default Kontakty;